.hero-wrapper {
  width: 100%;
  min-width: 100%;
  min-height: 100%;
  max-width: 100%;
  max-height: 100%;

  &__small {
    max-width: 1440px;
    margin: 0 auto;
    width: 100%;
    position: relative;
    padding: 11px 40px 0;
  }
}

@media (max-width: 1300px) {
  .hero-wrapper__small {
    max-width: 900px;
  }
}

@media (max-width: 600px) {
  .hero-wrapper__small {
    padding: 15px 15px 0;
  }
}