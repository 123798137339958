.head-text-wrapper {
  z-index: 3;
  position: absolute;
  height: 700px;
  top: 500px;
  left: 50%;
  transform: translateX(-50%);
}

.head-text {
  position: sticky;
  top: 160px;
  width: fit-content;
  -webkit-transform: translateZ(0);
  -webkit-backface-visibility: hidden;

  span {
    display: flex;
  }

  & .button-wrapper {
    width: 250px;
    height: 160px;
    margin-left: 20px;
    margin-top: 4px;
    font-family: 'Reem Kufi';
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 56px;
    text-align: center;
    text-transform: uppercase;
  }
}

@media (max-width: 1300px) {
  .head-text-wrapper {
    top: 300px;
    height: 500px;
  }

  .head-text {
    svg {
      width: 820px;
    }

    span svg {
      width: 625px;
    }

    .button-wrapper {
      font-size: 32px;
      line-height: 40px;
      width: 175px;
      height: 135px;
    }
  }
}

@media (max-width: 1000px) {
  .head-text {
    svg {
      width: 689px;
      height: fit-content;
    }

    span svg {
      width: 515px;
      height: auto;
      margin-top: 37px;
    }

    .button-wrapper {
      font-size: 28px;
      line-height: 32px;
      width: 155px;
      height: 115px;
      margin-top: 17px;
    }
  }
}

@media (max-width: 600px) {
  .head-text-wrapper {
    top: 513px;
    height: 274px;
  }

  .head-text {
    top: 360px;

    svg {
      width: 345px;
      height: 49px;
    }

    span {
      img {
        height: 50px;
        width: 50px;
        margin-left: 30px;
      }
      svg {
        width: 267px;
        height: 48px;
      }
    }

    .button-wrapper {
      font-size: 20px;
      line-height: 28px;
      width: 227px;
      height: 60px;
      margin: 0;
      padding: 10px 20px;
    }
  }

  .head-text-button-mobile {
    margin: 24px 0 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}