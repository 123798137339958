.text-block {
  width: 765px;
  height: 368px;
  background-image: url("../../../../assets/images/noises/noise_bg.png");
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 116px 48px;
  color: white;

  &__header {
    margin-bottom: 16px;
  }

  &__text {
    position: relative;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    & > span {
      position: relative;

      &:before {
        content: "";
        position: absolute;
        width: 105%;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        z-index: 0;
        top: 0;
        left: -2px;
        background-image: url("../../../../assets/images/tags/tag1.PNG");
      }

      & > span {
        position: relative;
        z-index: 1;
      }
    }
  }
}

@media (max-width: 1380px) {
  .text-block {
    width: 365px;
    padding: 20px 12px;
  }
}