.pre-screen {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100%;
  max-width: 100%;
  max-height: 100%;
  background: #222224;
  transition: 0.5s ease;
  overflow: hidden;

  & img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 100%;
    min-height: 100%;
  }

  & svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    height: 400px;
  }

  & .button-wrapper {
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);

    & img {
      min-height: unset;
      min-width: unset;
    }
  }
}

.disappear-screen {
  opacity: 0;
  pointer-events: none;
}

.invisible {
  opacity: 0;
  transition: 0.5s ease;
}

.appear {
  opacity: 1;
}

@media (max-width: 600px) {
  .pre-screen {
    & img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      min-width: 100%;
      min-height: 100%;
    }

    & svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 150px;
      height: 150px;
    }

    & .button-wrapper {
      position: absolute;
      bottom: 50px;
      left: 50%;
      transform: translateX(-50%);
      width: 160px;
      height: 45px;
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;

      & img {
        min-height: unset;
        min-width: unset;
      }
    }
  }
}