.page-wrapper {
  //max-width: 1440px;
  //margin: 0 auto;
  //width: 100%;
  //height: 100%;
  //position: relative;
  //padding: 11px 40px 0;
}
* {
  scroll-behavior: smooth;
}