@import "@/app/constants.scss";

.button-wrapper {
  background: $magneta;
  border: 3px solid $jet;
  width: 210px;
  height: 76px;
  color: $jet;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  cursor: pointer;

  & img {
    display: none;
  }

  & span {
    z-index: 1;
    padding: 3px 0 0;
  }

  &:hover {
    color: $magneta;
    & img {
      display: unset;
      position: absolute;
      z-index: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}