@import "@/app/constants.scss";

.kickstart {
  transition: 0.3s ease-out;
  position: fixed;
  left: 171px;
  top: 150%;
  transform: translateY(-50%);
  background-image: url("../../../../assets/images/tags/tag3.PNG");
  width: 590px;
  height: 218px;
  font-family: 'Reem Kufi';
  font-style: normal;
  font-weight: 600;
  font-size: 96px;
  line-height: 144px;
  padding-top: 23px;
  padding-left: 47px;
  letter-spacing: 5px;
  /* identical to box height */

  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;

  color: $jet;
}

@media (max-width: 1380px) {
  .kickstart {
    left: 45px;
  }
}

@media (max-width: 1000px) {
  .kickstart {
    left: 60px;
    width: 320px;
    height: 119px;
    font-weight: 600;
    font-size: 52px;
    line-height: 52px;
    padding-top: 12px;
    padding-left: 9px;
    letter-spacing: 5px;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

@media (max-width: 600px) {
  .kickstart {
    left: 45px;
    width: 184px;
    height: 68px;
    font-weight: 600;
    font-size: 28px;
    line-height: 28px;
    padding-top: 12px;
    padding-left: 9px;
    letter-spacing: 5px;
    background-repeat: no-repeat;
    background-size: cover;
  }
}