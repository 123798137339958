@import "@/app/constants.scss";

.white-pixel__block {
  width: 384px;
  height: 136px;
  background-image: url("../../../../assets/images/noises/noise25.png");
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-start;

  & .bh1 {
    padding: 20px;
    color: $jet;
  }

  & img {
    width: 384px;
    height: 136px;
  }
}