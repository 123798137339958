@import "@/app/constants.scss";

.footer {
  width: 100%;
  background-image: url("../../../../assets/images/noises/noise_bg.png");
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 16px;

  &__title {
    color: $magneta;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 24px;
    letter-spacing: 2px;
  }

  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;

    & .button-wrapper {
      height: 64px;

      &:nth-child(1) {
        width: 318px;
      }

      &:nth-child(2) {
        width: 104px;
        margin-left: 16px;
      }

      &:nth-child(3) {
        width: 104px;
        margin-left: 16px;
      }

      &:hover {
        svg path {
          fill: $magneta;
        }
      }

      & span {
        white-space: nowrap;
        padding: unset;
      }
    }
  }

  &__copyright {
    color: $magneta;

    &.h4 {
      white-space: nowrap;
    }
  }

  & .button-wrapper {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: unset;
    margin: unset;

    & span {
      display: flex;
      justify-content: center;
      align-items: center;

      &.h4 {
        padding-top: 2px;
      }
    }
  }
}

@media (max-width: 600px) {
  .footer {

    &__title {
      margin-bottom: 8px;
      letter-spacing: 1px;
      width: 345px;

      &.h3 {
        font-size: 14px;
        line-height: 20px;
        white-space: nowrap;
      }
    }

    &__container {
      margin-bottom: 8px;
    }

    &__container-mobile {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 8px;

      & .button-wrapper {
        height: 48px;
        width: 310px;

        &:nth-child(1) {
          width: 72px;
          height: 48px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: unset;
          margin: unset;
        }

        &:nth-child(2) {
          width: 72px;
          height: 48px;
          margin-left: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: unset;

          & svg {
            margin-top: -2px;
          }
        }

        & svg {
          width: 24px;
        }

        &:hover {
          svg path {
            fill: $magneta;
          }
        }
      }
    }

    &__copyright.h4 {
      font-size: 14px;
      line-height: 20px;
      white-space: nowrap;
    }
  }
}