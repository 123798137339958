.blank-window {
  width: 100%;
  height: 1200px;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1025px) {
  .blank-window {
    height: 600px;
  }
}

@media (max-width: 600px) {
  .blank-window {
    height: 280px;
  }
}