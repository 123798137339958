.bottom-section {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;

  &__block {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;

    & #networking {
      transition: all 1s ease;
      transform: translateX(-1500px);

      &.shift {
        transform: translateX(0px);
      }
    }

    & #marketing {
      transition: all 1s ease;
      margin-left: 40px;
      transform: translateX(1500px);

      &.shift {
        transform: translateX(0px);

      }
    }

    & #product {
      transition: all 1s ease;
      transform: translateX(-1500px);

      &.shift {
        transform: translateX(0px);
      }
    }
  }
}

#networking_img {
  width: 365px;
  height: 368px;
  background-color: #D9D9D9;
  transition: all 1s ease;
  margin-left: 40px;
  transform: translateX(1500px);

  &.shift {
    transform: translateX(0px);

  }
}

#marketing_img {
  width: 365px;
  height: 368px;
  background-color: #D9D9D9;
  transition: all 1s ease;
  transform: translateX(-1500px);

  &.shift {
    transform: translateX(0px);
  }
}

#product_img {
  width: 365px;
  height: 368px;
  background-color: #D9D9D9;
  transition: all 1s ease;
  margin-left: 40px;
  transform: translateX(1500px);

  &.shift {
    transform: translateX(0px);

  }
}

@media (max-width: 1000px) {
  .bottom-section {
    &__block {
      display: flex;
      justify-content: center;
      align-items: center;
      grid-column-gap: 20px;
      //flex-direction: column;
      //margin-bottom: 16px;

      &:nth-child(2) {
        //flex-direction: column-reverse;
      }

      & #networking {
        width: 345px;
        height: 340px;
        padding: 90px 40px;

        & h3 {
          font-size: 16px;
          line-height: 24px;
          white-space: nowrap;
        }

        & .text-block__text {
          font-size: 16px;
          line-height: 24px;
        }
      }

      & #marketing {
        width: 345px;
        height: 340px;
        font-size: 16px;
        line-height: 24px;
        margin: 0;
        padding: 90px 40px;


        & h3 {
          font-size: 16px;
          line-height: 24px;
          white-space: nowrap;
        }

        & .text-block__text {
          font-size: 16px;
          line-height: 24px;
        }
      }

      & #product {
        width: 345px;
        height: 340px;
        font-size: 16px;
        line-height: 24px;
        padding: 90px 40px;


        & h3 {
          font-size: 16px;
          line-height: 24px;
          white-space: nowrap;
        }

        & .text-block__text {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }

  #networking_img {
    width: 345px;
    height: 340px;
    margin-left: 0;
    //margin: 16px 0 0 0;
  }

  #marketing_img {
    width: 345px;
    height: 340px;
    margin-left: 0;
    //margin: 16px 0 0 0;
  }

  #product_img {
    width: 345px;
    height: 340px;
    margin-left: 0;
    //margin: 16px 0 0 0;
  }

}

@media (max-width: 600px) {
  .bottom-section {
    &__block {
      flex-direction: column;
      margin-bottom: 16px;

      &:nth-child(2) {
        flex-direction: column-reverse;
      }
    }
  }

  #networking_img {
    margin: 16px 0 0 0;
  }

  #marketing_img {
    margin: 16px 0 0 0;
  }

  #product_img {
    margin: 16px 0 0 0;
  }
}