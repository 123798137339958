@import "@/app/constants.scss";

.head_section {
  margin-top: 95px;
  position: relative;

  &__text {
    visibility: hidden;
    position: relative;
    margin: -345px auto 0;
    z-index: 3;
    width: fit-content;

    & span {
      display: flex;
    }

    & .button-wrapper {
      width: 250px;
      height: 160px;
      margin-left: 20px;
      margin-top: 4px;
      font-family: 'Reem Kufi';
      font-style: normal;
      font-weight: 600;
      font-size: 48px;
      line-height: 56px;
      text-align: center;
      text-transform: uppercase;
    }
  }
}

.logo_gif-central {
  position: fixed;
  top: 150%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1000px;
  z-index: 10;
  transition: 0.3s ease-out;
}

.middle-block {
  width: 100%;
  margin: 0 135px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;

  & .black-pixel__block {
    margin-top: 130px;
  }

  & .white-pixel__block {
    margin-top: 100px;
    z-index: 20;
  }
}

.section__kickstart {
  width: 100%;
  height: 1200px;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  z-index: 10;
}


#project {
  transition: 0.3s ease-out;
  position: fixed;
  right: 293px;
  top: 150%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;

  color: $jet;
}

@media (max-width: 1380px) {
  #project {
    right: 45px;
  }

  .middle-block {
    & .black-pixel__block {
      margin-top: 80px;
      z-index: 20;
    }

    & .white-pixel__block {
      margin-top: 60px;
    }
  }
}

@media (max-width: 1300px) {
  .head_section {
    & .button-wrapper {
      font-size: 32px;
      line-height: 40px;
      width: 175px;
      height: 135px;
    }

    &__text {
      margin: -235px auto 0;

      & svg {
        width: 820px;
      }

      & span svg {
        width: 625px;
      }
    }
  }
}

@media (max-width: 1000px) {
  .head_section {
    & .button-wrapper {
      font-size: 28px;
      line-height: 32px;
      width: 155px;
      height: 115px;
      margin-top: 17px;
    }

    &__text {
      margin: -190px auto 0;

      & svg {
        width: 689px;
        height: fit-content;
      }

      & span svg {
        width: 515px;
        height: auto;
        margin-top: 37px;
      }
    }
  }
}

@media (max-width: 1025px) {
  .logo_gif-central {
    width: 740px;
    left: 15px;
    transform: translateY(-50%);
  }

  .section__kickstart {
    height: 800px;
  }

  #project {
    right: 115px;
    font-size: 48px;
    line-height: 48px;
    margin-top: -10px;
  }
}

@media (max-width: 600px) {
  .head_section {
    & .button-wrapper {
      font-size: 20px;
      line-height: 28px;
      width: 227px;
      height: 60px;

      padding: 10px 20px;
    }

    &__text {
      margin: -50px auto 0;

      & svg {
        width: 345px;
        height: 49px;
      }

      & span {
        & img {
          height: 50px;
          width: 50px;
          margin-left: 30px;
        }
        & svg {
          width: 267px;
          height: 48px;
        }
      }
    }
  }

  .logo_gif-central {
    width: 375px;
    left: 15px;
    transform: translateY(-50%);
  }

  .middle-block {
    margin: 0;

    & .black-pixel__block {
      margin-top: 16px;
      align-self: center;
      text-align: start;
      width: 345px;
      height: 136px;

      & .bh1 {
        font-size: 16px;
        line-height: 24px;
        width: 100%;
      }
    }

    & .white-pixel__block {
      margin-top: 16px;
      align-self: center;
      text-align: left;
      width: 345px;
      height: 112px;

      & .bh1 {
        font-size: 16px;
        line-height: 24px;
        width: 100%;
      }
    }
  }

  #project {
    right: 65px;
    font-size: 20px;
    line-height: 28px;
  }

  .head_section__button-mobile {
    margin: 24px 0 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .head_section__text .button-wrapper {
    margin: 0;
  }

  .section__kickstart {
    height: 500px;
  }
}