.swiper-box {
  width: 176px;
  height: 176px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: #D9D9D9;
  box-shadow: 0px 48px 60px 6px rgba(49, 45, 45, 0.25);

  &__empty {
    width: 176px;
    height: 176px;
  }

  & img {
    width: 100%;
  }

  & > .first_slide_post_logo {
    width: 24px;
    height: 24px;
  }
}

.swiper {
  margin-left: -80px !important;
  overflow: visible !important;
}
.swiper-slider__container {
  overflow: hidden;
  height: 400px;
  padding-top: 50px;
  align-items: center;
  position: relative;
}

.pixelate {
  &-left {
    position: absolute;
    backdrop-filter: blur(10px);
    width: 200px;
    height: 400px;
    left: 0;
    top: 0;
    z-index: 10;
  }

  &-right {
    position: absolute;
    backdrop-filter: blur(10px);
    width: 200px;
    height: 400px;
    right: 0;
    top: 0;
    z-index: 10;
  }
}

.wave-animation {
  animation: wave 2s ease-in infinite;
}

@keyframes wave {
  0% {
    transform: rotateY(-0deg);
  }
  20% {
    transform: rotateY(-0deg);
  }
  52.5% {
    transform: rotateY(-20deg);
  }
  65% {
    transform: rotateY(-40deg);
  }
  87.5% {
    transform: rotateY(-20deg);
  }
  100% {
    transform: rotateY(-0deg);
  }
}


@media (max-width: 1380px) {
  .pixelate {
    &-left {
      width: 150px;
      height: 300px;
    }

    &-right {
      width: 150px;
      height: 300px;
    }
  }
}

@media (max-width: 1000px) {
  .pixelate {

    &-right {
      right: 1px;
    }
  }
}


@media (max-width: 600px) {
  .swiper {
    margin-left: 15px !important;
    overflow: visible !important;
  }
}
