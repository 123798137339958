* {
  border: 0;
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  &::-webkit-scrollbar {
    -webkit-animation-duration: 10s;
  }
  &::-webkit-scrollbar {
    width: 12px;
    background-color: #F5F5F5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #555;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }

  &::-webkit-scrollbar-thumb:active {
    background-color: #555;
  }

  &::-webkit-scrollbar-thumb:vertical {
    width: 12px;
  }

  &::-webkit-scrollbar-thumb:horizontal {
    height: 12px;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  &::-webkit-scrollbar-track {
    background-color: #F5F5F5;
  }
}

a {
  text-decoration: none;
}

.root {
  overflow: scroll;
}

.app-wrapper {
  min-height: 100vh;
  display: flex;

  &__preload {
    overflow: hidden;
    max-height: 100vh;
  }
}

@font-face {
  font-family: 'Reem Kufi';
  src: url('../assets/fonts/ReemKufi-Regular.ttf');
}

@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
.h0 {
  font-family: 'Reem Kufi';
  font-style: normal;
  font-weight: 600;
  font-size: 96px;
  line-height: 144px;
  text-transform: uppercase;
}

.h1 {
  font-family: 'Reem Kufi';
  font-style: normal;
  font-weight: 600;
  font-size: 72px;
  line-height: 108px;
  text-transform: uppercase;
}

.h2 {
  font-family: 'Reem Kufi';
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 56px;
  text-transform: uppercase;
}

.h3 {
  font-family: 'Reem Kufi';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  text-transform: uppercase;
}

.h4 {
  font-family: 'Reem Kufi';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  text-transform: uppercase;
}

.h5 {
  font-family: 'Reem Kufi';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
}

.bh1 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
}