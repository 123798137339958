.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 50;
  width: 100%;
  max-width: 1440px;
  padding-right: 80px;
}

@media (max-width: 1300px) {
  .header {
    max-width: 900px;
  }
}

@media (max-width: 600px) {
  .header {
    max-width: unset;
    width: 100%;
    padding-right: 30px;

    & img {
      width: 60px;
      height: 60px;
    }

    & .button-wrapper {
      width: 170px;
      height: 60px;
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      text-transform: uppercase;
      white-space: nowrap;
    }
  }
}