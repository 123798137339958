@import "@/app/constants.scss";

.swiper-molecule {

  margin-bottom: 10px;

  &__title {
    color: $jet;
    margin-bottom: 100px;
    margin-left: 195px;
  }

  &__subtitle {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 28px;
    text-transform: none;
  }
}

@media (max-width: 1000px) {
  .swiper-molecule {
    &__title {
      margin-bottom: 40px;
      margin-left: 40px;

      &.h1 {
        font-size: 48px;
        line-height: 48px;
      }
    }
  }
}

@media (max-width: 600px) {
  .swiper-molecule {
    &__title {
      font-size: 30px;
      line-height: 36px;
      margin-bottom: 28px;
      margin-left: 15px;

      &.h1 {
        font-size: 30px;
        line-height: 36px;
      }
    }

    &__subtitle {
      font-size: 18px;
    }
  }
}