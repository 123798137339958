.logo_header {
  cursor: pointer;

  & img {
    width: 78px;
    height: 78px;

    &[alt="logo"] {
      display: unset;
    }

    &[alt="logo_hover"] {
      display: none;
    }
  }

  &:hover {
    & img {
      &[alt="logo"] {
        display: none;
      }

      &[alt="logo_hover"] {
        display: unset;
        scale: 1.3;
      }
    }
  }
}