.video-head {
  & img {
    transform: scaleY(0.75) translateY(-17%);
}
}

@media (max-width: 600px) {
  .video-head {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 452px;
    overflow: hidden;

    & img {
      transform: unset;
      width: 904px;
      height: 452px;
    }
  }
}