@import "@/app/constants.scss";

.black-pixel__block {
  width: 384px;
  height: 200px;
  background-image: url("../../../../assets/images/noises/noise_bg.png");
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-end;

  & .bh1 {
    padding: 20px;
    color: white;
  }

  & img {
    width: 384px;
    height: 136px;
  }
}